import { useCan } from "~/composables/can"

export function useMiddleware(
    name: string,
    callback?: (params?: any) => void,
    rules?: boolean,
    redirect?: string
) {
    const can = useCan()
    const DEFAULT_REDIRECT_ROUTE = "/store"

    const validateIfCan = (middlewareParams: { name: string }[]) => {
        if (middlewareParams.length === 0) return true
        return middlewareParams.some((param) => {
            !can(param.name)
        })
    }

    return defineNuxtRouteMiddleware(async (to, from) => {
        if (!to.meta.middleware) return
        let middlewareParams: { name: string }[] = []
        if (to.meta.middleware.find((m) => m.startsWith(`${name}:`))) {
            middlewareParams.push({
                name: to.meta.middleware
                    .find((m) => m.startsWith(`${name}:`))
                    ?.split(":")[1],
            })
        }

        if (
            middlewareParams.length > 0 &&
            middlewareParams[0].name.includes(",")
        ) {
            middlewareParams = middlewareParams[0].name
                .split(",")
                .map((p) => ({ name: p }))
        }
        if (validateIfCan(middlewareParams) || !rules) {
            navigateTo(redirect ?? DEFAULT_REDIRECT_ROUTE)
            return
        }
        const slug = to.params.slug

        try {
            callback &&
                callback({
                    slug,
                    params:
                        middlewareParams.length > 0
                            ? middlewareParams
                            : undefined,
                })
        } catch (e) {
            console.log(e)
            navigateTo(redirect ?? DEFAULT_REDIRECT_ROUTE)
        }
    })
}
