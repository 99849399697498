import { useMiddleware } from "~/composables/useMiddleware"
import type { User } from "~/models/User"

export const getAuthUser = (params: any) => {
    const user = useSanctumUser<User>()
    return user
}

const defineNuxtRouteMiddleware = useMiddleware("checkProfileUser", getAuthUser)

export default defineNuxtRouteMiddleware
